body {
  margin: 0px;
}

h4 {
  display: flex;
  align-items: center;
}

.carta {
  position: relative;
  display: flex;
  width: 34px;
  height: 52px;
  background-color: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  padding-left: 3px;
  font-family: "Helvetica Neue";
  font-size: 16px;
}

.palo {
  position: absolute;
  bottom: 20%;
  font-family: "Helvetica Neue";
  font-size: 32px;
  height: 32px;
}

.roja {
  color: #ff0000;
}

.negra {
  color: #000000;
}

.joker {
  background: #F4DA16;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding-left: 0px;
  width: 37px;
}

.mano {
  width: 100%;
  display: flex;
  /*gap: 4px;*/
  align-items: center;
}

.mano > * {
  margin: 2px;
}

.contenedor {
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #707070;
  padding: 5px;
  font-family: "Helvetica Neue";
  font-size: 12px;
  color: #6226ee;
}

.vacia {
  background-color: #f0f0f0;
  border: 1px dashed #707070;
}

.espacio {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.puntos {
  padding: 2px 3px 2px 2px;
  color: #000000;
  font-family: "Helvetica Neue";
  font-size: 12px;
  font-weight: bold;
  border: 2px solid #000000;
  border-radius: 50%;
}

.cuantasCartas {
  width: 16px;
  height: 22px;
  border: 1px solid #707070;
  background-color: #6A3A3A;
}

.cuantasCartasValor {
  color: #6A3A3A;
  padding-bottom: 2px;
  font-family: "Helvetica Neue";
  font-size: 13px;
  font-weight: bold;
}

.accion {
  margin-left: auto !important;
  color: #65BE0D;
  border: 2px solid #65BE0D;
  padding: 3px 6px 3px 6px;
  font-family: "Helvetica Neue";
  font-size: 10px;
  font-weight: bold;
}

.pumba {
  color: #FC0000;
  border: 2px solid #FC0000;
  padding: 3px;
  font-family: "Helvetica Neue";
  font-size: 10px;
  font-weight: bold;
}

.botones {
  /*gap: 8px;*/
  justify-content: flex-end;
  margin-right: 6px;
}

.botones > * {
  margin: 4px !important;
}

.tapada {
  background-color: #6A3A3A;
}

.cabecera {
  background-color: #30A9DE;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 100px;
  align-items: center;
  padding: 8px;
}

.titulo {
  color: #ffffff;
  font-family: "Helvetica Neue";
  font-size: 24px;
  font-weight: bold;
}

.subtitulo {
  color: #ffffff;
  font-family: "Helvetica Neue";
  font-size: 18px;
  font-weight: normal;
}

.etiqueta {
  color: #ffffff;
  font-family: "Helvetica Neue";
  font-size: 12px;
  font-weight: normal;
  margin: 0;
}

.MuiIconButton-label {
  flex-direction: column !important;
  justify-content: space-between !important;
}

.contenido {
  position: fixed;
  width: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.minicabecera-container {
  border: 1px solid #30A9DE;
  border-radius: 8px;
  box-sizing: border-box;
}

.minicabecera {
  position: relative;
  display: flex;
  background-color: #30A9DE;
  color: #ffffff;
  font-family: "Helvetica Neue";
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  padding: 8px;
  border-radius: 8px 8px 0 0;
}

.minicabecera-abajo {
  position: relative;
  display: flex;
  flex-direction: column;
  /*gap: 8px;*/
  background-color: #ffffff;
  color: #707070;
  font-family: "Helvetica Neue";
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  justify-content: space-evenly;
  padding: 16px;
  border-radius: 0 0 8px 8px;
}

.minicabecera-abajo > * {
  margin: 4px !important;
}

.anuncio {
  color: #30A9DE;
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-weight: normal;
  gap: 8px;
  padding-top: 8px;
}

.lista-jugadores {
  background-color: rgba(48,169,222,.25);
  border: 1px solid #30A9DE;
  color: #30A9DE;
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  padding: 8px;
  margin-top: 12px;
}

.listado {
  text-align: center;
  margin-top: 8px;
  font-style: italic;
  font-weight: normal;
}

.puntos-en-mano {
  color: #6A3A3A;
  font-family: "Helvetica Neue";
  font-size: 12px;
  font-weight: bold;
}

.puntaje-actualizado {
  margin-left: auto;
  color: #30A9DE;
  border: 2px solid #30A9DE;
  padding: 3px;
  font-family: "Helvetica Neue";
  font-size: 10px;
  font-weight: bold;
}

.fin {
  z-index: 10;
  min-height: 100vh; 
  position: absolute;
  width: 100%;
  display: flex;
  background-color: rgba(0,0,0,0.85);
}

.dashboard {
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: absolute;
}

.resultado {
  color: #ffffff;
  font-family: "Helvetica Neue";
  font-size: 36px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.medal-color-0 {
  color: #FFD700;
}
.medal-color-1 {
  color: #C5CED4;
}
.medal-color-2 {
  color: #B87333;
}
.medal-color-3 {
  color: rgba(0,0,0,0);
}

canvas {
  position: absolute;
  margin-top: -20%;
}